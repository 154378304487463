<template>
  <div class="d-inline-block">
    <a href="#0" @click="onClick"
      ><font-awesome-icon icon="address-book" /> {{ title }}</a
    >
    <CModal
      :title="title"
      :show.sync="show"
      :closeOnBackdrop="false"
      :centered="true"
    >
      <table class="table" v-if="contacts.length > 0">
        <thead>
          <tr>
            <th>{{ $t("role.orders.contacts.name") }}</th>
            <th>{{ $t("role.orders.contacts.email") }}</th>
            <th>{{ $t("role.orders.contacts.phone") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in contacts" :key="item.email">
            <td>{{ item.name }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.phone }}</td>
          </tr>
        </tbody>
      </table>
      <template #footer>
        <CButton color="primary" variant="outline" @click="onClose">{{
          $t("role.orders.contacts.closeBtn")
        }}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: "OrdersContacts",

  props: {
    title: {
      type: String,
      default: "Contatti",
    },

    contacts: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      show: false,
    };
  },

  methods: {
    onClick() {
      this.show = true;
    },

    onClose() {
      this.show = false;
    },
  },
};
</script>
