<template>
  <div>
    <CButton color="primary" @click="onClick">{{ title }}</CButton>
    <CModal
      :title="title"
      :show.sync="show"
      :closeOnBackdrop="false"
      :centered="true"
    >
      <div v-for="item in items" :key="item.key" class="mb-3">
        <h2>{{ item.label }}</h2>
        <ul>
          <li v-if="prints[item.key] && prints[item.key].all">
            <a :href="printLink(`${prints[item.key].all}`)" target="_blank">{{
              $t("role.orders.manage.print.allProducts")
            }}</a>
          </li>
          <li v-if="prints[item.key] && prints[item.key].B">
            <a :href="printLink(`${prints[item.key].B}`)" target="_blank">{{
              $t("role.orders.manage.print.packagedProducts")
            }}</a>
          </li>
          <li v-if="prints[item.key] && prints[item.key].F">
            <a :href="printLink(`${prints[item.key].F}`)" target="_blank">{{
              $t("role.orders.manage.print.refrigeratedProducts")
            }}</a>
          </li>
        </ul>
      </div>

      <template #footer>
        <CButton color="primary" variant="outline" @click="onClose">{{
          $t("role.orders.manage.print.closeBtn")
        }}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: "OrdersPrints",

  props: {
    title: {
      type: String,
      default: "Stampe",
    },

    prints: {
      type: Object,
      required: true,
    },

    types: {
      type: Array,
      default: () => ["wh_islands", "wh_list", "wh_orders", "wh_producer"],
    },
  },

  data() {
    return {
      show: false,
      typesMap: [
        {
          key: "wh_producer",
          label: this.$t("role.orders.manage.print.planProducers"),
        },
        {
          key: "wh_list",
          label: this.$t("role.orders.manage.print.planOrders"),
        },
        {
          key: "wh_orders",
          label: this.$t("role.orders.manage.print.ordersToPrepare"),
        },
        { key: "wh_islands", label: this.$t("planDelivery") },

        {
          key: "island_orders",
          label: this.$t("role.orders.manage.print.island"),
        },
        {
          key: "producer_products",
          label: this.$t("role.orders.manage.print.producersProducts"),
        },
        {
          key: "producer_orders",
          label: this.$t("role.orders.manage.print.producersOrders"),
        },
        {
          key: "producer_orders_direct",
          label: this.$t("role.orders.manage.print.directDeliveryOrders"),
        },
      ],
    };
  },

  computed: {
    items() {
      const self = this;
      return this.typesMap.filter((item) => {
        return self.types.includes(item.key);
      });
    },
  },

  methods: {
    onClick() {
      this.show = true;
    },

    onClose() {
      this.show = false;
    },

    printLink(path) {
      return `${window.env.LOONITY_BASE_API_URL}${path}`;
    },
  },
};
</script>
