<template>
  <div v-if="isWeightProduct()">
    <div v-if="mustBeWeighed()">
      <CButton
        variant="outline"
        color="warning"
        size="sm"
        :disabled="haveIncidentOpen()"
        @click.prevent="onWeightButton()"
      >
        {{ $t("role.orders.manage.weight.btnWeigh") }}
      </CButton>
    </div>
    <div v-else>
      <CButton
        variant="outline"
        color="primary"
        size="sm"
        :disabled="haveIncidentOpen()"
        @click.prevent="onWeightButton()"
      >
        {{ $t("role.orders.manage.weight.btnWeighAlt") }}
      </CButton>
    </div>
  </div>
</template>

<script>
import get from "lodash/get";
export default {
  name: "OrdersWeightButton",

  components: {},

  props: {
    row: { type: Object, required: true },
    parentRowIndex: { required: true },
  },

  methods: {
    isWeightProduct() {
      return get(this.row, "ppm.lot.productproducer.pw", false);
    },

    mustBeWeighed() {
      return get(this.row, "need_w", false);
    },

    onWeightButton() {
      const { parentRowIndex, row } = this;
      const entryData = { parentRowIndex, row };
      this.$store.commit("productWeight/openModal", {
        entryData,
      });
    },

    haveIncidentOpen() {
      return get(this.row, "incident.close") === false;
    },
  },
};
</script>
